<template>
  <div>
    <el-dialog
      :title="mode==='view'?'查看':'处理'"
      :visible.sync="dialogFormVisible"
      width="40%"
      :before-close="handleClose">
      <div class="block">
        <el-timeline>
          <el-timeline-item timestamp="数据情况" placement="top">
            <el-card>
              <el-table
                :data="tableData"
                size="mini"
                border
              >
                <el-table-column
                  prop="acquit_at"
                  label="报警日期"
                  align="center"
                  width="100"
                  header-align="center"
                >
                <template slot-scope="scope">
                    {{fixTime(scope.row.acquit_at, 0)}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="typ"
                  label="报警类型"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.typ == 1">超标报警</span>
                    <span v-else>异常离线报警</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="c_emissions"
                  label="油烟浓度"
                  align="center"
                  width="100"
                  header-align="center"
                />
                <el-table-column
                  prop="c_granule"
                  label="颗粒物浓度"
                  align="center"
                  width="100"
                  header-align="center"
                />
                <el-table-column
                  prop="c_hydrocarbon"
                  label="非甲烷总烃浓度"
                  align="center"
                  width="120"
                  header-align="center"
                />
              </el-table>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="报警核实&第三方巡检" placement="top">
            <el-card>
              <h4>核实状态: <template v-if="info.status == 0"><span style="color:#aaaaaa;margin-left:38px;">核实中</span></template><template v-else><span style="color:#4091ff;margin-left:38px;">已核实</span></template></h4>
              <el-form
                :model="formData"
                :rules="formRule"
                ref="form"
                size="mini"
                label-width="108px"
                label-position="left"
                :disabled="mode == 'view'"
              >
                <el-form-item prop="process_at" label="核实日期">
                  <el-date-picker
                    placeholder="核实日期"
                    type="date"
                    value-format="timestamp"
                    format="yyyy-MM-dd"
                    v-model="formData.process_at"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item
                  prop="process_reason"
                  label="报警原因"
                >
                  <template v-if="info.typ == 1">
                    <el-select v-model="formData.process_reason">
                      <el-option label="净化器效率低" value="净化器效率低"/>
                      <el-option label="净化器需清洗" value="净化器需清洗"/>
                      <el-option label="净化器故障" value="净化器故障"/>
                      <el-option label="净化器未使用" value="净化器未使用"/>
                      <el-option label="净化器与风机风量不匹配" value="净化器与风机风量不匹配"/>
                      <el-option label="净化器处理风量与实际风量不匹配" value="净化器处理风量与实际风量不匹配"/>
                      <el-option label="净化器老化" value="净化器老化"/>
                      <el-option label="设备原因" value="设备原因"/>
                    </el-select>
                  </template>
                  <template v-else>
                    <el-select v-model="formData.process_reason">
                      <el-option label="净化器未通电" value="净化器未通电"/>
                      <el-option label="净化器未开启" value="净化器未开启"/>
                      <el-option label="净化器故障" value="净化器故障"/>
                      <el-option label="商家线路故障" value="商家线路故障"/>
                      <el-option label="商家未开启监测仪电源" value="商家未开启监测仪电源"/>
                      <el-option label="商家未营业" value="商家未营业"/>
                      <el-option label="商家已停业" value="商家已停业"/>
                      <el-option label="设备原因" value="设备原因"/>
                    </el-select>
                  </template>
                </el-form-item>
                <el-form-item
                  prop="process_info"
                  label="核实详情"
                >
                  <el-input
                    type="textarea"
                    autosize
                    v-model.trim="formData.process_info"
                  />
                </el-form-item>
                <el-form-item
                  prop="rectify_date"
                  label="整改期限"
                >
                  <el-select v-model="formData.rectify_date">
                    <el-option label="1" value="1"/>
                    <el-option label="2" value="2"/>
                    <el-option label="3" value="3"/>
                    <el-option label="4" value="4"/>
                    <el-option label="5" value="5"/>
                    <el-option label="6" value="6"/>
                    <el-option label="7" value="7"/>
                  </el-select>
                </el-form-item>
                <el-form-item
                  prop="processing_address"
                  label="获取定位">
                  <baidu-map
                    class="bm-view"
                    :center="mapCenter"
                    :zoom="mapZoom"
                    ak="770b4c07458f53896ff0abd948755e20"
                    @ready="handleMapReady"
                    v-if="mode == 'edit'"
                  >
                    <bm-geolocation
                      anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                      :showAddressBar="true"
                      :autoLocation="true"
                      @locationSuccess="getPosition"
                    ></bm-geolocation>
                  </baidu-map>
                  <span>{{ formData.processing_address ? formData.processing_address : '暂无定位' }}</span>
                </el-form-item>
                <el-form-item
                  prop="MaintenancePicS"
                  label="核实照片">
                  <template v-if="mode == 'edit' &&  info.status == 0">
                    <el-upload
                      ref="upload"
                      accept=".jpg, .jpeg, .png"
                      list-type="picture-card"
                      :action="upload()"
                      :on-success="handleAvatarSuccess"
                      :on-remove="handleRemove"
                      multiple
                    >
                      <i class="el-icon-plus"/>
                      <div slot="tip">上传照片（支持jpg、jpeg、png）</div>
                    </el-upload>
                  </template>
                  <template v-else>
                    <div v-if="formData.process_pics && formData.process_pics[0]">
                      <el-image v-for="item in formData.process_pics" :key="item" :src="item" :preview-src-list="formData.process_pics" style="width: 100px;height: 100px;margin-left:10px;" />
                    </div>
                  </template>
                </el-form-item>
              </el-form>
              <div style="float:right;padding-bottom:20px;margin-right:20px;" v-if="mode == 'edit' && info.status == 0">
                <el-button type="primary" size="mini" @click.native="handleSubmit(true)">提交</el-button>
              </div>
            </el-card>
          </el-timeline-item>
          <el-timeline-item v-if = "info.status != 0" timestamp="整改情况" placement="top">
            <el-card>
              <h4>整改状态: <template v-if="info.rectify_status == 1"><span style="color:#aaaaaa;margin-left:38px;">整改中</span></template><template v-else-if="info.rectify_status == 2"><span style="color:#4091ff;margin-left:38px;">整改完成</span></template><template v-else><span style="color:#660000;margin-left:38px;">整改失败</span></template></h4>
              <h4>整改日期:  <span style="font-size: 14px;margin-left:38px;color:#aaaaaa;font-weight: 400;">{{fixTime(info.process_at, 0)}}</span><span style="margin-left:20px;color:#aaaaaa;font-weight: 400;">~</span><span style="font-size: 14px;margin-left:20px;color:#aaaaaa;font-weight: 400;">{{fixTime(info.process_at, info.rectify_date)}}</span></h4>
              <template v-if="info.rectify_status > 1">
                <el-table
                :data="tableHandleData"
                size="mini"
                border
              >
                <el-table-column
                  prop="AcquitDate"
                  label="数据日期"
                  align="center"
                  width="100"
                  header-align="center"
                >
                <template slot-scope="scope">
                    {{fixTime(scope.row.AcquitDate, 0)}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="typ"
                  label="当日状态"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.Status == 1">达标</span>
                    <span v-else-if="scope.row.Status == 2">超标</span>
                    <span v-else-if="scope.row.Status == 3">正常离线</span>
                    <span v-else>异常离线</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="CEmissions"
                  label="油烟浓度"
                  align="center"
                  width="100"
                  header-align="center"
                />
                <el-table-column
                  prop="CGranule"
                  label="颗粒物浓度"
                  align="center"
                  width="100"
                  header-align="center"
                />
                <el-table-column
                  prop="CHydrocarbon"
                  label="非甲烷总烃浓度"
                  align="center"
                  width="120"
                  header-align="center"
                />
              </el-table>
              </template>
            </el-card>
          </el-timeline-item>
          <el-timeline-item v-if="(info.status == 3 || info.status == 2) && info.rectify_status == 3" timestamp="执法管理" placement="top">
            <el-card>
              <h4>处理状态: <template v-if="info.status == 2"><span style="color:#aaaaaa;margin-left:38px;">执法中</span></template><template v-else><span style="color:#4091ff;margin-left:38px;">已执法</span></template></h4>
              <el-form
                :model="formData_1"
                :rules="formRule_1"
                ref="form1"
                size="mini"
                label-width="108px"
                label-position="left"
                :disabled="mode == 'view'"
              >
                <template v-if="eventRecords.length>0">
                  <el-form-item label="处理记录">
                    <el-timeline>
                      <el-timeline-item v-for="item in eventRecords" :key="item.index"  :timestamp="'第'+item.index+'次处理记录'" placement="top">
                        <el-card>
                          <h4>处理日期: <span style="font-size: 14px;margin-left:38px;color:#aaaaaa;font-weight: 400;">{{dateFormat(item.handle_at)}}</span></h4>
                          <h4>处理人员: <span style="font-size: 14px;margin-left:38px;color:#aaaaaa;font-weight: 400;">{{item.handle_name}}</span></h4>
                          <h4>处理措施: <span style="font-size: 14px;margin-left:38px;color:#aaaaaa;font-weight: 400;">{{item.solution}}</span></h4>
                          <div v-if="item.handle_pics.length > 0">
                            <el-image v-for="(pic,index) in item.handle_pics" :key="index" :src="pic" :preview-src-list="item.handle_pics" style="width: 100px;height: 100px;margin-left:10px;" />
                          </div>
                        </el-card>
                      </el-timeline-item>
                    </el-timeline>
                  </el-form-item>
                </template>
                <el-form-item prop="handle_at" label="处理日期">
                  <el-date-picker
                    placeholder="处理日期"
                    type="date"
                    value-format="timestamp"
                    format="yyyy-MM-dd"
                    v-model="formData_1.handle_at"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item
                  prop="solution"
                  label="处理措施"
                >
                  <el-input
                    type="textarea"
                    autosize
                    v-model.trim="formData_1.solution"
                  />
                </el-form-item>
                <el-form-item
                  prop="status"
                  label="处理结果"
                >
                  <el-select
                    style="width: 100%"
                    v-model="formData_1.status"
                    clearable
                    placeholder='处理结果'
                  >
                    <el-option label="处理中" :value="2"></el-option>
                    <el-option label="已完成" :value="3"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  prop="handle_pics"
                  label="处理照片">
                  <template v-if="mode == 'edit' &&  info.status == 2">
                    <el-upload
                      ref="upload"
                      accept=".jpg, .jpeg, .png"
                      list-type="picture-card"
                      :action="upload()"
                      :on-success="handleAvatar1Success"
                      :on-remove="handle1Remove"
                      multiple
                    >
                      <i class="el-icon-plus"/>
                      <div slot="tip">上传照片（支持jpg、jpeg、png）</div>
                    </el-upload>
                  </template>
                  <template v-else>
                    <div v-if="formData_1.handle_pics && formData_1.handle_pics[0]">
                      <el-image v-for="item in formData_1.handle_pics" :key="item" :src="item" :preview-src-list="formData_1.handle_pics" style="width: 100px;height: 100px;margin-left:10px;" />
                    </div>
                  </template>
                </el-form-item>
              </el-form>
              <div style="float:right;padding-bottom:20px;margin-right:20px;" v-if="mode == 'edit' && info.status == 2">
                <el-button type="primary" size="mini" @click.native="handleSubmit1(true)">提交</el-button>
              </div>
            </el-card>
          </el-timeline-item>
          <el-timeline-item v-if="info.status == 3" timestamp="事件完结" placement="top">
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import BmGeolocation from "vue-baidu-map/components/controls/Geolocation.vue";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import {
  dateFormater
} from '@/util'
import conf from '@/config'
export default {
  name: "AlarmViewDialog",
  components: {BaiduMap, BmGeolocation},
  data() {
    return {
      id: null,
      mode: 'view',
      info: {},
      alarmData:{},
      handleData:{},
      progress: [],
      tableData:[],
      formData: {},
      formData_1: {},
      tableHandleData: [],
      eventRecords:[],
      formRule: {
        process_reason: [{required: true, message: '不可为空'}],
        process_info: [{required: true, message: '不可为空'}],
        process_at: [{required: true, message: '不可为空'}],
        rectify_date: [{required: true, message: '不可为空'}],
      },
      formRule_1: {
        solution: [{required: true, message: '不可为空'}],
        handle_at: [{required: true, message: '不可为空'}],
      },
      mapZoom: 14,
      mapCenter: {lng: 120.306305, lat: 30.424877},
      dialogFormVisible: false,
    }
  },

  methods: {
    fixTime(time, day) {
      if (time) {
        let arr = time.split(" ")
        if (day == 0) {
          return arr[0]
        } else {
          let date = new Date(arr[0]); // 你可以替换为你需要的日期
          date.setDate(date.getDate() + day);
          var y = date.getFullYear();
          var m = date.getMonth() + 1;
          m = m < 10 ? ('0' + m) : m;
          var d = date.getDate();
          d = d < 10 ? ('0' + d) : d;
          return y + '-' + m + '-' + d;
        }
      }
    },
    getEventInfo(id) {
      this.eventRecords = []
      this.$get('admin/listEvent/info/' + id,{typ: 2}).then(res => {
        for(var i = 0; i < res.eventHandleRecords.length; i++) {
          res.eventHandleRecords[i].index = i+1
          if (res.eventHandleRecords[i].handle_pics != "") {
            res.eventHandleRecords[i].handle_pics = res.eventHandleRecords[i].handle_pics.split(",")
          } else {
            res.eventHandleRecords[i].handle_pics = []
          }
        }
        this.eventRecords = res.eventHandleRecords
      })
    },
    getData() {
      this.tableData = []
      this.tableHandleData = []
      this.formData = {}
      this.formData_1 = {}
      this.$get('admin/alarmManagement/info/' + this.id).then(res => {
        this.info = res.info
        this.alarmData = res.alarm_data
        this.handleData = res.handle_data
        this.tableData.push({
          acquit_at: this.alarmData.AcquitDate,
          c_emissions: this.alarmData.CEmissions,
          c_granule: this.alarmData.CGranule,
          c_hydrocarbon: this.alarmData.CHydrocarbon,
          typ: this.info.typ
        })
        var proPics = res.info.process_pics.split(',')
        var imgs = []
        for (var i = 0; i < proPics.length; i++) {
          if (proPics[i]) {
            imgs.push(proPics[i])
          }
        }
        this.formData = {
          "process_reason":     res.info.process_reason,
          "process_info":       res.info.process_info,
          "process_at":         res.info.process_at != "-"? Date.parse(new Date(res.info.process_at)):Date.parse(new Date()),
          "rectify_date":       res.info.rectify_date>0?res.info.rectify_date:1,
          "processing_address": res.info.processing_address,
          "process_pics":       imgs,
        }
        if (this.info.rectify_status > 1) {
          this.tableHandleData.push({
            AcquitDate: this.handleData.AcquitDate,
            CEmissions: this.handleData.CEmissions,
            CGranule: this.handleData.CGranule,
            CHydrocarbon: this.handleData.CHydrocarbon,
            Status: this.handleData.Status
          })
        }
        var handlePics = res.info.handle_pics.split(',')
        var images = []
        for (var i = 0; i < handlePics.length; i++) {
          if (handlePics[i]) {
            images.push(handlePics[i])
          }
        }
        this.formData_1 = {
          "solution":     res.info.solution,
          "handle_at":    res.info.handle_at != "-"? Date.parse(new Date(res.info.handle_at)):Date.parse(new Date()),
          "handle_pics":  images,
          "status":       res.info.status,
        }
      })
      this.getEventInfo(this.id)
    },
    dealDialog(optType = 'view', row = {}) {
      this.id = row.id
      this.mode = optType
      this.dialogFormVisible = true
      this.getData()
    },
    handleSubmit1() {
      this.$refs.form1.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {})
            .then(() => {
              let params = new URLSearchParams()
              var paramData = {
                status:        this.formData_1.status,
                handle_at:     this.formData_1.handle_at / 1000,
                solution:      this.formData_1.solution,
                handle_pics:   this.formData_1.handle_pics.join(','),
              }
              Object.entries(paramData).forEach(([key, val]) => {
                params.append(key, val)
              })
              this.$post('admin/alarmManagement/edit/' + this.id, params)
                .then(() => {
                  setTimeout(()=> {
                    this.$emit('on-refresh')
                  }, 500)
                  this.$message({
                    type: 'success',
                    message: "执法成功"
                  })
                  this.dialogFormVisible = false
                })
            })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {})
            .then(() => {
              let params = new URLSearchParams()
              var paramData = {
                status: 1,
                process_reason: this.formData.process_reason,
                process_info:   this.formData.process_info,
                process_at:     this.formData.process_at / 1000,
                rectify_date:   this.formData.rectify_date,
                processing_address: this.formData.processing_address,
                process_pics:       this.formData.process_pics.join(','),
              }
              Object.entries(paramData).forEach(([key, val]) => {
                params.append(key, val)
              })
              this.$post('admin/alarmManagement/edit/' + this.id, params)
                .then(() => {
                  setTimeout(()=> {
                    this.$emit('on-refresh')
                  }, 500)
                  this.$message({
                    type: 'success',
                    message: "核实成功"
                  })
                  this.dialogFormVisible = false
                })
            })
        }
      })
    },
    submit() {
      this.$refs.form1.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
              let { Id, Resolution, MaintenancePicS } = this.formData1
              const Status = 'FINISHED'
              this.$post('admin/updateHealthCodeRecord', { Id, Resolution, Status, MaintenancePicS })
                .then(() => {
                  setTimeout(()=> {
                    this.$emit('on-refresh')
                  }, 500)
                  this.$message({
                    type: 'success',
                    message: "审核成功"
                  })
                  this.dialogFormVisible = false
                })
            })
        }
      })
    },
    submitCheck() {
      this.$refs.form2.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            let { Id, Resolution, Status } = this.formData2
            this.$post('admin/updateHealthCodeRecord', {Id, Resolution, Status,})
              .then(() => {
                setTimeout(()=> {
                  this.$emit('on-refresh')
                }, 500)
                this.$message({
                  type: 'success',
                  message: "审核成功"
                })
                this.dialogFormVisible = false
              })
          })
        }
      })
    },
    handleClose() {
      this.dialogFormVisible = false
      this.formData = {}
      this.formData1 = {}
    },
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    handleAvatarSuccess(res, file) {
      this.formData.process_pics.push(res.Data)
    },
    handleRemove(file, fileList) {
      const i = this.formData.process_pics.indexOf(file.response.Data)
      this.formData.process_pics.splice(i, 1)
    },
    handleAvatar1Success(res, file) {
      this.formData_1.handle_pics.push(res.Data)
    },
    handle1Remove(file, fileList) {
      const i = this.formData_1.handle_pics.indexOf(file.response.Data)
      this.formData_1.handle_pics.splice(i, 1)
    },
    handleMapReady() {
      if (this.formData.Lng && this.formData.Lat) {
        this.mapCenter = {lng: this.formData.Lng, lat: this.formData.Lat}
      }
    },
    getPosition(pos) {
      this.formData.Lng = pos.point.lng.toString()
      this.formData.Lat = pos.point.lat.toString()
      this.formData.processing_address = pos.addressComponent['province'] + pos.addressComponent['city'] +
        pos.addressComponent['district'] + pos.addressComponent['street'] +
        pos.addressComponent['streetNumber']
    },
    dateFormat(r) {
      return dateFormater(r, true);
    },
  }
}
</script>
<style scoped>
.bm-view {
  width: 100%;
  height: 150px;
}
</style>
