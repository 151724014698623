<!--事件处理-报警管理-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-select
            v-model="filter.typ"
            placeholder="报警类型"
            clearable
            @keyup.native.enter="getData"
            @change="getData"
          >
            <el-option :value="1" label="超标报警"/>
            <el-option :value="2" label="异常离线报警"/>
            <el-option :value="3" label="不正常使用净化器报警"/>
          </el-select>
        </el-form-item>
        <el-form-item>
           <el-cascader
            placeholder="所属单位"
            @change="getData"
            v-model="filter.owner"
            :options="customerTree"
            :props="customerProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="filter.acquit_at"
            type="date"
            value-format="timestamp"
            @change="getData"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="filter.status"
            placeholder="事件状态"
            clearable
            @keyup.native.enter="getData"
            @change="getData"
          >
            <el-option :value="0" label="核实中"/>
            <el-option :value="1" label="整改中"/>
            <el-option :value="2" label="执法中"/>
            <el-option :value="3" label="已完结"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="filter.rectify_status"
            placeholder="整改状态"
            clearable
            @keyup.native.enter="getData"
            @change="getData"
          >
            <el-option :value="1" label="整改中"/>
            <el-option :value="2" label="整改完成"/>
            <el-option :value="3" label="整改未完成"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleRefresh">刷新</el-button>
          <el-button type="primary" @click="openExportSizeInput">导出</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="index" label="#" align="center" width="55"/>
        <el-table-column
          prop="locale_name"
          label="监测点"
          width="140"
          show-overflow-tooltip
          header-align="center">
        </el-table-column>
        <el-table-column
          prop="addr"
          label="地址"
          width="140"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="mn"
          label="设备编号"
          width="140"
          show-overflow-tooltip
          header-align="center"
          align="center"
        />
        <el-table-column
          prop="acquit_at"
          width="140"
          label="派单时间"
          align="center"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="event_type"
          width="140"
          show-overflow-tooltip
          label="报警类型"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="event_status"
          label="事件状态"
          align="center"
          width="140"
          show-overflow-tooltip
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          prop="process_at"
          label="核实时间"
          align="center"
          header-align="center"
          width="140"
          show-overflow-tooltip
        />
        <el-table-column
          prop="process_reason"
          label="报警原因"
          align="center"
          header-align="center"
          width="140"
          show-overflow-tooltip
        />
        <el-table-column
          prop="process_info"
          label="核实结果"
          align="center"
          header-align="center"
          width="140"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="rectify_desc"
          label="整改状态"
          align="center"
          header-align="center"
          width="140"
          show-overflow-tooltip
        />
        <el-table-column
          prop="rectify_message"
          label="整改情况"
          align="center"
          header-align="center"
          width="140"
          show-overflow-tooltip
        />
        <el-table-column
          prop="handle_at"
          label="执法处理时间"
          align="center"
          header-align="center"
          width="140"
          show-overflow-tooltip
        />
        <el-table-column
          prop="solution"
          label="执法处理情况"
          align="center"
          header-align="center"
          width="140"
          show-overflow-tooltip
        />

        <el-table-column
          label="操作"
          align="center"
          width="120"
          fixed="right"
          header-align="center"
        >
          <template slot-scope="scope">
            <i
              v-if="scope.row.status != 4"
              title="编辑"
              @click="handleCodeView(scope.row.id, 'edit')"
              class="opt el-icon-edit"
            />
            <i
              title="查看"
              @click="handleCodeView(scope.row.id, 'view')"
              class="opt el-icon-view"
            />
            <i
              title="删除"
              @click="handleDel(scope.row)"
              class="opt el-icon-delete"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>
    <alarm-view-dialog ref="alarmViewDialog" @on-refresh="getData"/>
  </section>
</template>

<script>
import {mapState} from "vuex";
import {getUserInfo, ownerJoin} from "@/util";
import AlarmViewDialog from "@/component/alarmManagement/AlarmViewDialog.vue";

export default {
  name: 'AlarmManagement',
  components: {AlarmViewDialog},
  data() {
    return {
      loading: false,
      datalist: {},
      userInfo: {},
      viewVisible: false,
      id: null,
      viewMode: null,
      filter: {
        status: null,
        owner: null,
        typ: null,
        acquit_at: null,
        rectify_status: null,
        page: 1,
        size: 10,
        pageSizes: [10, 20, 30, 50]
      },
      customerProps: {
        value: 'Id',
        label: 'Name',
        children: 'children',
        checkStrictly: true
      },
      filterRecord: {
        StartAt: 0,
        Size: 20,
        page: 1,
        size: 20
      },
    }
  },
  computed: {
    ...mapState(["clientHeight", "customerTree"]),
  },
  mounted() {
    this.userInfo = getUserInfo().Info
    this.getData()
  },
  methods: {
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleExport(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    handleExport(num) {
      const param = {
        page: this.filter.page,
        size: num,
        is_download: true
      }
      if (this.filter.owner && this.filter.owner.length > 0) {
        param.owner = `${ownerJoin(this.filter.owner, this.userInfo)}`
      }
      if (this.filter.status !== null && this.filter.status !== "") {
        param.status = this.filter.status
      }
      if (this.filter.rectify_status) {
        param.rectify_status = this.filter.rectify_status
      }
      if (this.filter.acquit_at) {
        param.acquit_at = this.filter.acquit_at / 1000
      }
      if (this.filter.typ) {
        param.typ = this.filter.typ
      }
      this.$get("admin/alarmManagement/list", param).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
      })
    },
    handleRefresh(clear = true) {
      if (clear) {
        this.filter.page = 1
        this.filter.status = null
        this.filter.rectify_status = null
        this.filter.owner = null
        this.filter.acquit_at = null
        this.filter.typ = null
      }
      this.getData()
    },
    getData() {
      this.loading = true
      const param = {
        page: this.filter.page,
        size: this.filter.size,
        Param: {}
      }
      if (this.filter.owner && this.filter.owner.length > 0) {
        param.owner = `${ownerJoin(this.filter.owner, this.userInfo)}`
      }
      if (this.filter.status !== null && this.filter.status !== "") {
        param.status = this.filter.status
      }
      if (this.filter.rectify_status) {
        param.rectify_status = this.filter.rectify_status
      }
      if (this.filter.acquit_at) {
        param.acquit_at = this.filter.acquit_at / 1000
      }
      if (this.filter.typ) {
        param.typ = this.filter.typ
      }
      this.$get("admin/alarmManagement/list", param).then(res => {
        this.datalist.content = res.list
        this.datalist.total = res.count
        this.loading = false
      })
    },

    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleCodeView(id, mode) {
      this.$refs['alarmViewDialog'].dealDialog(mode, { id: id })
    },
    handleDel(row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      })
        .then(() => {
          this.$post('admin/alarmManagement/delete/' + row.id)
            .then(() => {
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              this.getData()
            })
        })
    },
  }
}
</script>

<style lang="scss" scoped>

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}

.single-img > img {
  width: 160px;
  height: 160px;
}

.view-img {
  width: 178px;
  height: 178px;
  margin-right: 10px;
}

.remove-img {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
  background: rgba(0, 0, 0, .5);
  transition: transform .3s;
  -webkit-transition: transform .3s;
}

.remove-img:hover {
  cursor: pointer;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.locale-progress {
  margin-top: 12px;
  margin-right: 40px;
}
</style>
